<template>
  <div class="pay-result"
       @click="showShareSuccessDialog = false">
    <div class="share-guide"
         v-if="showWechat"
         @click="showWechat = false">
      <img src="@/assets/images/wechat-guide.png"
           alt="下载页背景图" />
      <div class="share-guide-text">
        <p>点击右上角“…”，</p>
        <p>分享给你身边的朋友</p>
      </div>
    </div>
    <div class="title-tips">
      下载宝秤APP，享会员权益及已获奖励。
      <div class="download-button"
           @click="toDownload">下载APP</div>
    </div>

    <div class="status">
      <img src="@/assets/images/6718.png"
           alt=""
           class="status-icon" />
      支付成功
    </div>

    <div class="power"
         v-if="info.integral || info.user_back || info.red_package">
      <div class="power-title">本次支付获得</div>
      <div class="power-item"
           v-if="info.integral">
        <img src="@/assets/images/power3.png"
             alt="" />
        <div class="power-item-content">
          <div>
            <span class="color">+{{ info.integral }}</span> 宝秤积分
          </div>
          <div class="text">可抵扣现金支付</div>
        </div>
        <div class="power-item-button"
             @click="openGetDialog"
             :class="{disabled: isDisabledGet}">{{isDisabledGet?'已':''}}领取</div>
      </div>
      <div class="power-item"
           v-if="info.user_back">
        <img src="@/assets/images/power1.png"
             alt="" />
        <div class="power-item-content">
          <div>
            平台补贴金额<span class="color">{{ info.user_back }}元</span>
          </div>
          <div class="text">可提现</div>
        </div>
        <div class="power-item-button"
             @click="showTakeDialog = true">提现</div>
      </div>
      <div class="power-item"
           v-if="info.red_package">
        <img src="@/assets/images/power2.png"
             alt="" />
        <div class="power-item-content">
          <div>
            抽<span class="color">3680元</span>现金红包
          </div>
          <div class="text">现金红包可提现</div>
        </div>
        <div class="power-item-button"
             @click="toHongbao">抽红包</div>
      </div>
    </div>

    <div class="share-button"
         @click="showSharePopup = true"
         v-if="!isHideShare">
      分享文章给好友

      <img src="@/assets/images/mouse.png"
           alt="" />
    </div>

    <div class="my-reward"
         @click="goRecordPage()"
         v-if="!isHideShare">
      【查看平台补贴】
    </div>

    <van-popup v-model="showPopup"
               position="bottom"
               round
               :close-on-click-overlay="false">
      <div class="popup">
        <div class="popup-title">请输入你享用权益的手机号码</div>
        <div style="font-size: 10px;text-align: center;color: #ff7816;margin-bottom:15px">若不填写手机号码，会员权益、平台补贴等权益将丢失</div>
        <div class="popup-form">
          <div class="popup-form-title">手机号</div>
          <input type="text"
                 class="input"
                 placeholder="请输入手机号"
                 v-model="form.mobile"
                 maxlength="11" />
        </div>
        <div class="popup-form">
          <div class="popup-form-title">验证码</div>
          <div class="input-content">
            <input type="text"
                   class="input"
                   v-model="form.sms_code"
                   placeholder="请输入验证码"
                   maxlength="6" />
            <span :class="{'code':!interval}"
                  :style="{ color: !interval ? '#00a0ac' : '#A3A3A3' }"
                  @click="sendMessage">{{ codeBtnStr }}</span>
          </div>
        </div>
        <div style="color: #808388;font-size: 12px;margin-top:10px">1、未注册的手机号登录后将自动创建宝秤账号</div>
        <div style="color: #808388;font-size: 12px;margin-top:10px">2、手机绑定后，会员权益属于当前账号</div>
        <div class="popup-button"
             @click="bindWechat">确定</div>
      </div>
    </van-popup>

    <van-popup v-model="showSharePopup"
               position="bottom"
               round>
      <van-grid :border="false"
                :column-num="isWechat ? 4 : 2">
        <van-grid-item text="微信好友"
                       v-if="isWechat"
                       @click="openWechatShare">
          <van-image slot="icon"
                     :width="45"
                     :height="45"
                     :src="require('@/assets/images/wechat.png')" />
        </van-grid-item>
        <van-grid-item text="朋友圈"
                       v-if="isWechat"
                       @click="openWechatShare">
          <van-image slot="icon"
                     :width="45"
                     :height="45"
                     :src="require('@/assets/images/circle.png')" />
        </van-grid-item>
        <van-grid-item text="海报下载"
                       @click="createPosters">
          <van-image slot="icon"
                     :width="45"
                     :height="45"
                     :src="require('@/assets/images/download.png')" />
        </van-grid-item>
        <van-grid-item text="复制链接"
                       @click="copy">
          <van-image slot="icon"
                     :width="45"
                     :height="45"
                     :src="require('@/assets/images/link.png')" />
        </van-grid-item>
      </van-grid>
      <div class="share-cancel"
           @click="showSharePopup = false">取消</div>
    </van-popup>

    <van-dialog v-model="showGetDialog"
                :show-confirm-button="false">
      <div class="dialog">
        <img src="@/assets/images/6718.png"
             alt=""
             class="dialog-icon" />
        <p class="dialog-title">领取成功</p>
        <p>请前往APP使用</p>
        <div class="dialog-footer">
          <div class="dialog-button dialog-cancel-button"
               @click="showGetDialog = false">
            取消
          </div>
          <div class="dialog-button"
               @click="toDownload">下载APP</div>
        </div>
      </div>
    </van-dialog>

    <van-dialog v-model="showTakeDialog"
                :show-confirm-button="false">
      <div class="dialog">
        <p class="dialog-title">提现提示</p>
        <p style="padding-bottom: 10px">
          请前往APP的<span style="color: #00a0ac">我的-资金账户</span>进行提现
        </p>
        <div class="dialog-footer">
          <div class="dialog-button dialog-cancel-button"
               @click="showTakeDialog = false">
            取消
          </div>
          <div class="dialog-button"
               @click="toDownload">下载APP</div>
        </div>
      </div>
    </van-dialog>

    <div v-if="showPosters"
         class="posters-mask"
         @click="showPosters = false">
      <div v-if="postersHref">
        <img :src="postersHref"
             alt=""
             class="posters-image" />
      </div>
      <div id="posters"
           class="posters"
           v-else>
        <div class="posters-cover"
             :style="{ 'background-image': `url(${articleDetail.img})` }"></div>
        <div class="posters-title">{{ articleDetail.title }}</div>
        <div class="posters-footer">
          <div class="posters-info">
            <div style="padding-bottom: 10px">{{ form.mobile }}</div>
            <div>分享一篇好文给你，</div>
            <div>邀请你一起阅读</div>
          </div>
          <div style="text-align: center">
            <div class="posters-qr-code"
                 :style="{ 'background-image': `url(${postersQrCode})` }"></div>
            <div>扫码查看</div>
          </div>
        </div>
      </div>

      <div class="posters-button">长按海报，保存到本地</div>
    </div>

    <!-- 分享后提示弹框 -->
    <!-- <div class="share-success" v-if="!isHideShare">
      <div>
        <img
          src="@/assets/images/default_success.png"
          alt
          style="width: 24px; height: 24px"
        />
        <div class="title">已分享</div>
      </div>
      <p>点击底部【查看我的现金奖励>】</p>
      <p>可查看现金奖励</p>
    </div> -->
  </div>
</template>

<script>
import { getSmsCode } from '@/api/hongbao'
import { bindWechat, update_wx_anonymous_info } from '@/api/wechat'
import html2canvas from 'html2canvas'
import QRCode from 'qrcode'
import { getArticleShareUrl, getWechatConfig } from '@/api/config'
import { getArticleDetail, getPayResult } from '@/api/article'
import { getPlatformRatio } from '@/api/public'

export default {
  data () {
    return {
      showPopup: false,
      showSharePopup: false,
      number: '',
      showGetDialog: false,
      showTakeDialog: false,
      showShareSuccessDialog: false, // 分享成功后弹窗
      showRecordEntry: false, // 查看现金奖励入口
      form: {
        mobile: '',
        sms_code: ''
      },
      codeBtnStr: '获取验证码',
      interval: 0,
      shareLink: '',
      articleDetail: {},
      showPosters: false,
      postersQrCode: '', // 海报二维码
      info: {
        integral: '', // # 为空时 不显示积分栏目
        red_package: '', // # 为空时 不显示红包栏目
        user_back: '' // # 为空时 不显示返现栏目
      },
      showWechat: false,
      wx: null,
      postersHref: '',
      isHideShare: true,
      isWechat: false,
      isDisabledGet: false,
      loginData: {}
    }
  },
  async mounted () {
    document.title = '结果详情'

    this.isDisabledGet = localStorage.getItem('isDisabledGet')

    this.isWechat = this.$isWechat()

    getPlatformRatio({}).then(res => {
      if (this.$route.query.record_code.startsWith('A') || this.$route.query.commission === '0' || !res.data.data.plat_rate || res.data.data.plat_rate === '100') { // commission'0'不分佣,plat_rate 平台分佣比例
        this.isHideShare = true
      } else {
        this.isHideShare = false
      }
    })

    console.error(this.$route.query, 'this.$route.query')

    const mobile = this.$route.query.mobile || localStorage.getItem('payPhone')
    if (!mobile) {
      // 没有手机号，需要绑定
      this.showPopup = true
    } else {
      // 有手机号，用在海报上
      this.form.mobile = `${mobile.slice(0, 3)}****${mobile.slice(-4)}`
    }

    // 支付结果
    this._getPayResult()


    // 文章详情
    const articleDetail = await getArticleDetail({
      article_code: this.$route.query.article_code
    })

    this.articleDetail = articleDetail.data.data

    // 链接二维码
    getArticleShareUrl({
      article_code: this.$route.query.article_code, // 文章id
      record_code: this.$route.query.record_code, // 总代code 平台默认为空
      user_code: this.$route.query.user_code // 总代code 平台默认为空
    }).then((res) => {
      this.shareLink = res.data.data

      // 生成链接二维码
      QRCode.toDataURL(this.shareLink, { rendererOpts: { quality: 1 } }).then(
        (result) => {
          this.postersQrCode = result
        }
      )
      this._getWechatConfig()
    })
  },
  methods: {
    _getPayResult () {
      getPayResult({
        product_id: this.$route.query.product_id,
        order_code: this.$route.query.order_code
      }).then((res) => {
        this.info = res.data.data
      })
    },
    openGetDialog () {
      if (this.isDisabledGet) {
        return
      }
      this.showGetDialog = true
      this.isDisabledGet = true
      localStorage.setItem('isDisabledGet', 1)
    },
    _getWechatConfig () {
      // 获取微信配置
      getWechatConfig({
        url: location.href.split('#')[0]
      }).then((config) => {
        this.configData = config
        setTimeout(() => {
          this.setConfig(config)
        }, 10)
      })
    },
    setConfig (config) {
      wx.config({
        debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
        appId: this.$route.query.appid, // 必填，公众号的唯一标识
        timestamp: config.timestamp, // 必填，生成签名的时间戳
        nonceStr: config.nonceStr, // 必填，生成签名的随机串
        signature: config.signature, // 必填，签名
        jsApiList: ['updateAppMessageShareData', 'updateTimelineShareData', 'onMenuShareTimeline', 'onMenuShareAppMessage'] // 必填，需要使用的JS接口列表
      })

      wx.ready(() => {
        // 需在用户可能点击分享按钮前就先调用
        wx.updateAppMessageShareData({
          title: this.articleDetail.title, // 分享标题
          desc: '分享一篇好文给你，邀请你一起阅读', // 分享描述
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.articleDetail.img, // 分享图标
          success: () => {
            // this.$toast('分享成功')
            // alert('配置分享链接成功', 'updateAppMessageShareData')
            // this.showShareSuccessDialog = true
            // this.showRecordEntry = true
          },
          cancel: () => {
            // alert('配置分享链接失败')
            // 用户取消分享后执行的回调函数
            // alertMsg('取消分享')
          }
        })

        wx.updateTimelineShareData({
          title: this.articleDetail.title, // 分享标题
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.articleDetail.img, // 分享图标
          success: () => {
            // alert('配置分享链接成功', 'updateTimelineShareData')
            // this.$toast('分享成功')
            // this.showShareSuccessDialog = true
            // this.showRecordEntry = true
          },
          cancel: () => {
            // alert('配置分享链接失败')
            // 用户取消分享后执行的回调函数
            // alertMsg('取消分享')
          }
        })

        wx.onMenuShareTimeline({
          title: this.articleDetail.title, // 分享标题
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.articleDetail.img, // 分享图标
          success: () => {
            console.log('分享成功')
          }
        })

        wx.onMenuShareAppMessage({
          title: this.articleDetail.title, // 分享标题
          desc: '分享一篇好文给你，邀请你一起阅读', // 分享描述
          link: this.shareLink, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl: this.articleDetail.img, // 分享图标
          success: () => {
            console.log('分享成功')
          }
        })

        wx.error(function (res) {
          // alert(JSON.stringify(res) + '>>>>>>>失败')
        })
      })
    },
    createPosters () {
      this.showPosters = true
      this.$nextTick(() => {
        html2canvas(document.getElementById('posters'), {
          useCORS: true,
          allowTaint: false,
          proxy: this.articleDetail.img,
          backgroundColor: 'rgba(0,0,0,0)'
        }).then((canvas) => {
          const img = canvas.toDataURL()
          this.postersHref = img
        })
      })
    },
    saveCanvas () {
      // https://blog.csdn.net/qq_32859565/article/details/83273248
      html2canvas(document.getElementById('posters'), {
        useCORS: true,
        allowTaint: false,
        proxy: this.articleDetail.img,
        backgroundColor: 'rgba(0,0,0,0)'
      }).then((canvas) => {
        const img = canvas.toDataURL()
        // const img = canvas.toDataURL('image/png', { quality: 1 });
        const dlLink = document.createElement('a')
        dlLink.download = '海报'
        dlLink.href = img
        document.body.appendChild(dlLink)
        dlLink.click()
        document.body.removeChild(dlLink)
        this.showPosters = false
        this.showSharePopup = false
        this.$toast('已保存')
        // this.wx.previewImage({
        //   current: img, // 当前显示图片的http链接
        //   urls: [img] // 需要预览的图片http链接列表
        // })
      })
    },
    toDownload () {
      location.href = 'http://i.suzi.com.cn/#/download'
    },
    toHongbao () {
      // location.href = 'http://i.suzi.com.cn/#/hongbao'

      var host = window.location.host
      let url = 'http://192.168.0.193:8080/#/hongbao'
      if (host === 'demo.suzi.com.cn') {
        url = 'http://demo.suzi.com.cn/i/#/hongbao' // 测试域名
      } else if (host === 'i.suzi.com.cn') {
        url = 'http://i.suzi.com.cn/#/hongbao' // 正式域名
      } else if (host === 'release.suzi.com.cn') {
        url = 'http://release.suzi.com.cn/i/#/hongbao' // 正式域名
      }
      location.href = url
    },
    sendMessage () {
      getSmsCode({
        phone_number: this.form.mobile
      })
        .then((res) => {
          this.interval = 60
          this.timer()
        })
        .catch((err) => {
          this.$toast(err.msg)
        })
    },
    timer () {
      this.interval--
      if (this.interval > 0) {
        this.codeBtnStr = '重新获取(' + this.interval + 's)'
        setTimeout(() => {
          this.timer()
        }, 1000)
      } else {
        this.codeBtnStr = '获取验证码'
      }
    },
    bindWechat () {
      if (this.form.mobile.length > 11) {
        return this.$toast('手机号不能超过11位')
      }
      if (this.form.mobile.sms_code > 6) {
        return this.$toast('验证码不能超过6位')
      }
      let query = this.$route.query
      bindWechat({
        data: {
          username: query.nickname,
          password: '123456',
          email: '',
          nickname: query.nickname,
          other_auth: {
            site: '1',
            site_uname: query.nickname,
            site_avatar: query.headimgurl,
            open_id: query.open_id,
            uid: query.uid,
            access_token: query.access_token,
          },
        },
        current_role: '2', // # 指定角色  2:贸易商 3:服务商
        mobile: this.form.mobile, //手机号
        sms_code: this.form.sms_code, // 验证码或666666

      })
        .then((res) => {
          this.showPopup = false
          this.loginData = res

          this.updateWxAnonymousInfo(res)
          localStorage.setItem('payPhone', this.form.mobile)
        })
        .catch((err) => {
          this.$toast(err.msg || '请求失败')
        })
    },
    updateWxAnonymousInfo (data) {
      update_wx_anonymous_info({
        org_id: data.org_id, // # 指定角色  2:贸易商 3:服务商
        user_code: data.user_code, //手机号
        uid: this.$route.query.uid, // 验证码或666666
        phone: this.form.mobile,
        order_code: this.$route.query.order_code
      }).then(res => {
        console.log(res)
        this._getPayResult()
      }).catch(err => {
        console.log(err)
      })
    },
    copy () {
      this.$copyText(this.shareLink)
      this.showSharePopup = false
      this.$toast('复制成功，快去分享吧')
    },
    openWechatShare () {
      this.showSharePopup = false
      this.showWechat = true
    },
    goRecordPage () {
      const query = {
        org_id: this.$route.query.org_id ? this.$route.query.org_id : this.loginData.org_id,
        mobile: this.$route.query.mobile ? this.$route.query.mobile : this.loginData.mobile,
        user_code: this.$route.query.user_code ? this.$route.query.user_code : this.loginData.user_code
      }
      this.$router.push({
        name: 'articleHome',
        query
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.code {
  width: 82px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border: 0.5px solid #00a0ac;
  border-radius: 4px;
  font-size: 14px;
  color: #00a0ac;
}
.pay-result {
  min-height: 100vh;
  background: #f3f4f6;
}
.title-tips {
  background: rgba(0, 0, 0, 0.6);
  height: 32px;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
  font-size: 14px;

  .download-button {
    background: #00a0ac;
    height: 20px;
    width: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    border-radius: 4px;
  }
}

.status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 25px 0;
  font-size: 16px;
  background: #fff;
  margin-bottom: 10px;

  .status-icon {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
  }
}

.power {
  background: #fff;
  padding: 20px 0 5px;

  .power-title {
    font-size: 16px;
    padding-bottom: 10px;
    padding-left: 15px;
  }
}

.power-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;

  &:not(:last-child) {
    border-bottom: 1px solid #eee;
  }

  img {
    width: 30px;
    margin-right: 16px;
    flex-shrink: 0;
  }

  .power-item-content {
    flex: 1;
  }

  .color {
    color: #ff6d33;
  }

  .text {
    font-size: 12px;
    color: #808388;
    padding-top: 4px;
  }

  .power-item-button {
    flex-shrink: 0;
    height: 24px;
    width: 65px;
    background: #fc8534;
    color: #fff;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
  }

  .disabled {
    background: #999;
  }
}

@keyframes click {
  0% {
    transform: translate(10px, 15px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.share-button {
  position: fixed;
  left: 40px;
  right: 40px;
  bottom: 65px;
  background: #fc8534;
  color: #fff;
  font-size: 18px;
  border-radius: 60px;
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 8px 0 0 #e24e18;

  img {
    width: 58px;
    position: absolute;
    right: -26px;
    top: 20px;
    animation: click 1s linear infinite alternate;
  }
}

.my-reward {
  font-size: 14px;
  color: #00a0ac;
  text-align: center;
  position: fixed;
  left: 40px;
  right: 40px;
  bottom: 30px;
}

.popup {
  padding: 0 30px;

  .popup-title {
    padding: 25px 0px 10px 0px;
    text-align: center;
    font-size: 16px;
  }

  .popup-form {
    border-bottom: 1px #ddd solid;
    margin-bottom: 10px;

    .input {
      border: none;
      outline: none;
      padding: 8px 0;
      color: #202122;
    }

    .popup-form-title {
      color: #505255;
      font-size: 13px;
    }
  }

  .input-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #00a0ac;
    padding: 8px 0px;
  }

  .popup-button {
    background: #00a0ac;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    margin: 70px 0 30px;
  }
}

.share-cancel {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  font-size: 14px;
  border-top: 1px solid #eee;
}

.dialog {
  text-align: center;
  padding: 20px;
  color: #505255;
  font-size: 14px;
  line-height: 1;

  .dialog-icon {
    height: 30px;
    width: 30px;
    margin-bottom: 10px;
  }

  .dialog-title {
    font-size: 18px;
    padding-bottom: 18px;
    color: #202122;
  }

  .dialog-footer {
    display: flex;
    padding-top: 18px;

    .dialog-button {
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: #fff;
      background: #00a0ac;
      border-radius: 4px;
      flex: 1;
    }

    .dialog-cancel-button {
      color: #505255;
      background: #f3f4f6;
      margin-right: 10px;
    }
  }
}

.posters-image {
  width: 343px;
  height: 431px;
}

.posters-button {
  background: #00bc86;
  padding: 6px 15px;
  border-radius: 60px;
  font-size: 14px;
  margin-top: 20px;
}

.posters-mask {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #fff;

  .posters {
    background: #fff;
    width: 343px;
    height: 431px;
    border-radius: 15px;
    padding: 15px 10px;

    .posters-cover {
      width: 100%;
      height: 170px;
      border-radius: 15px 15px 0 0;
      // object-fit: cover;
      overflow: hidden;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }

    .posters-title {
      font-size: 14px;
      color: #202122;
      padding-top: 20px;
      padding-bottom: 30px;
      margin-bottom: 30px;
      border-bottom: 1px dashed #eee;
    }

    .posters-footer {
      font-size: 12px;
      color: #808080;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
    }

    .posters-qr-code {
      height: 80px;
      width: 80px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }
}

.share-guide {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9;
  background: rgba($color: #000000, $alpha: 0.6);
  // padding-top: 150px;
  color: #fff;

  img {
    width: 130px;
    position: absolute;
    right: 25px;
    top: 5px;
  }

  .share-guide-text {
    position: absolute;
    top: 160px;
    text-align: center;
    right: 70px;
    line-height: 1.8;
    font-size: 16px;
  }
}

.share-success {
  text-align: center;
  color: #ffffff;
  padding: 16px 0;
  width: 60.4vw;
  // height: 36.8vw;
  opacity: 0.5;
  background: #000000;
  border-radius: 16px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .title {
    font-size: 16px;
    padding: 10px 0;
  }
  p {
    font-size: 12px;
    line-height: 18px;
  }
}
</style>
