import { api } from '@/plugins/axios'
import { mangeApi } from '@/plugins/mangeAxios'

// 获取文章详情
export function getArticleDetail (params) {
  return api('/extension_article/detail', params)
}

// 获取文章详情
export function getPayResult (params) {
  return mangeApi('/generate/generate_order/result', params)
}
